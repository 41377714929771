<template>
  <div>
    <Header />
    <section class="py-2 py-md-3 py-lg-5 bg-light unList-bestSeller">
      <section class="un-section-seller margin-t-20 container">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2 class="fw-bold title-gradient">Create Ads</h2>
            <p>
              Promote your dapps on DinoDapps and increase your dapps
              transaction
            </p>
          </div>
        </div>
      </section>

      <div class="container margin-t-10">
        <div class="col-12 card">
          <div class="card-body">
            <form @submit.prevent="createAds()">
              <div class="modal-body">
                <div class="form-group">
                  <label class="text-white">Ads Name</label>
                  <input
                    v-model="form.name"
                    type="text"
                    name="name"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('name') }"
                  />
                  <has-error :form="form" field="name"></has-error>
                </div>
                <div class="form-group">
                  <label class="text-white">Photo</label>
                  <input
                    type="file"
                    ref="file"
                    id="file"
                    @change="fileImage"
                    name="photo"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('photo') }"
                  />
                  <has-error :form="form" field="photo"></has-error>
                </div>
                <div class="form-group">
                  <label class="text-white">Ads Title</label>
                  <input
                    v-model="form.title"
                    type="text"
                    name="title"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('title') }"
                  />
                  <has-error :form="form" field="title"></has-error>
                </div>
                <div class="form-group">
                  <label class="text-white">Created Date</label>
                  <input
                    v-model="form.created_date"
                    type="text"
                    name="created_date"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('created_date') }"
                  />
                  <has-error :form="form" field="created_date"></has-error>
                </div>
                <div class="form-group">
                  <label class="text-white">Link</label>
                  <input
                    v-model="form.link"
                    type="text"
                    name="link"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('link') }"
                  />
                  <has-error :form="form" field="link"></has-error>
                </div>
                <div class="form-group">
                  <label class="text-white">Ads Description</label>
                  <input
                    v-model="form.description"
                    type="text"
                    name="description"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('description') }"
                  />
                  <has-error :form="form" field="description"></has-error>
                </div>
                <div class="form-group d-none">
                  <label class="text-white">Last Update</label>
                  <input
                    v-model="form.last_update"
                    type="text"
                    name="last_update"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('last_update') }"
                  />
                  <has-error :form="form" field="last_update"></has-error>
                </div>
                <!-- <div class="form-group d-none">
                  <label class="text-white">Items (json)</label>
                  <input
                    v-model="form.items"
                    type="text"
                    name="items"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('items') }"
                  />
                  <has-error :form="form" field="items"></has-error>
                </div> -->
                <div class="form-group">
                  <label class="text-white">Ads Type</label>
                  <select
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('ads_type') }"
                    v-model="form.ads_type"
                  >
                    <option disabled value="">Please select one</option>
                    <option value="ads-homepage">Ads Homepage</option>
                    <option value="ads-dappspage">Ads dApps Page</option>
                  </select>
                  <has-error :form="form" field="ads_type"></has-error>
                </div>
                <div class="form-group d-none">
                  <label class="text-white">Is Active</label>
                  <input
                    v-model="form.is_active"
                    type="text"
                    name="is_active"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('is_active') }"
                  />
                  <has-error :form="form" field="is_active"></has-error>
                </div>
                <div class="form-group d-none">
                  <label class="text-white">Is Start</label>
                  <input
                    v-model="form.is_start"
                    type="text"
                    name="is_start"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('is_start') }"
                  />
                  <has-error :form="form" field="is_start"></has-error>
                </div>
                <div class="form-group d-none">
                  <label class="text-white">Is Until</label>
                  <input
                    v-model="form.is_until"
                    type="text"
                    name="is_until"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('is_until') }"
                  />
                  <has-error :form="form" field="is_until"></has-error>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-start">
                <button type="submit" class="btn btn-success">
                  Create New Ads
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Form from "vform";

export default {
  name: "CreateAds",
  components: { Header, Footer },
  data() {
    return {
      adss: {},
      form: new Form({
        id: "",
        name: "",
        photo: "",
        title: "",
        created_date: "",
        link: "",
        description: "",
        last_update: "",
        items: [""],
        ads_type: "",
        is_active: 0,
        is_start: "",
        is_until: "",
      }),
    };
  },
  methods: {
    fileImage(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      if (file["size"] < 2111775) {
        reader.onloadend = (event) => {
          //console.log('RESULT', reader.result)
          this.form.photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("File size can not be bigger than 2 MB");
      }
    },
    createAds() {
      this.form
        .post(process.env.VUE_APP_API + "api/ads/submit-advertise")
        .then((response) => {
          this.$swal({
            icon: "success",
            title: response.data.data.message,
          });
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Some error occured! Please try again",
          });
        });
    },
  },
};
</script>

<style>
</style>